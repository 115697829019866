import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Antd from 'ant-design-vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import 'ant-design-vue/dist/antd.css'
import waterfall from 'vue-waterfall2'
import 'vue-bricks/lib/vueBricks.css'
import VueBricks from 'vue-bricks'
import FOOOCUS_API_BASE_URL from './global.js'
import Vant from 'vant';
import 'vant/lib/index.css'


Vue.prototype.global = FOOOCUS_API_BASE_URL
Vue.use(VueBricks)
Vue.config.productionTip = false


Vue.use(Vant)
Vue.use(Antd)
Vue.use(ElementUI)
Vue.use(waterfall)

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
