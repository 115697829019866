import Vue from 'vue'
import VueRouter from 'vue-router'
import LoginPage from '@/views/LoginPage.vue'
import TabsPage from '@/views/TabsPage.vue'

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
};

const routes = [
  {
    path: '/login',
    name: '登录',
    component: LoginPage
  },
  {
    path: '/403',
    name: '403',
    component: () => import('@/exception/403.vue')
  },
  
  {
    path: '/',
    name: '登录',
    redirect: '/login',
    component: TabsPage,
    children: [
      {
        path: '/home',
        name: '首页',
        component: () => import('@/views/HomePage.vue'),
      },
      {
    path: '/AIdesignOffice',
    name: 'AI设计室',
    component: () => import('@/views/AIdesignOffice.vue')
      },
    //   {
    // path: '/AIformulationRoom',
    // name: 'AI制定室',
    // component: () => import('@/exception/403.vue')
    //   },
      {
    path: '/EnterpriseServices',
    name: '企业服务',
    component: () => import('@/views/EnterpriseServices.vue')
      },
      {
        path: '/personalcenter',
        name: '个人中心',
        component: () => import('@/views/PersonalCenter.vue')
      },
      {
        path: '/membercenter',
        name: '会员中心',
        component: () => import('@/views/MemberCenter.vue')
      }
      
    ]
  },

  // {
  //   path: '/home',
  //   name: '首页',
  //   component: () => import('@/views/TabsPage.vue')
  // },
  // {
  //   path: '/AIdesignOffice',
  //   name: 'AI设计室',
  //   component: () => import('@/views/AIdesignOffice.vue')
  // }
]

const router = new VueRouter({
  // mode: 'history',
  // base: process.env.BASE_URL,
  routes
})

export default router
