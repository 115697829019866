<template>
  <div>
    <!-- <div class="grid-content bg-purple"> -->
    <el-menu :default-active="activeIndex2" :router="true" class="el-menu-demo" mode="horizontal"
      active-text-color="#ffd04b" text-color="#fff" background-color="transparent">
      <el-row class="elmenu">
        <el-col :span="4" class="logo"><img src="@/assets/logo.png" alt="" style="height: 8vh;"></el-col>
        <el-col :span="8">
          <el-row :gutter="20">
            <el-col :span="6">
              <el-menu-item index="home" class="elmenuitem" style="font-size: large;">首页</el-menu-item>
            </el-col>
            <el-col :span="6">
              <el-menu-item index="AIdesignOffice" class="elmenuitem">AI设计室</el-menu-item>
            </el-col>
            <!-- <el-col :span="6">
              <el-menu-item index="AIformulationRoom" class="elmenuitem">AI制定室</el-menu-item>
            </el-col> -->
            <el-col :span="6">
              <!-- <el-menu-item index="EnterpriseServices" class="elmenuitem">企业服务</el-menu-item> -->
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12">
          <div class="badge_avatar">
            <!-- <el-badge is-dot class="item" style="margin-right: 30px;"> -->
            <!-- <i class="el-icon-message" style="font-size: 4vh; color: #fff;"></i> -->
            <!-- <el-button class="share-button" icon="el-icon-message" type="primary"></el-button> -->
            <!-- </el-badge> -->
            <div class="me-vip" @click="Mevip" v-if="account">
              会员中心
            </div>


            <div class="avatar" @click="PersonalCenterClick" v-if="account">
              <a-popover>
                <template slot="content">
                  <p style="margin: 0; cursor: pointer;" @click="Logout">退出登录</p>
                </template>
                <el-avatar src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"
                  @error="errorHandler">
                  <img src="https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png" />
                </el-avatar>
              </a-popover>

            </div>
            <div class="login" @click="SignIn" v-else>
              登录 / 注册
            </div>

          </div>

        </el-col>
      </el-row>
    </el-menu>
    <router-view></router-view>
    <div></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeIndex: '1',
      activeIndex2: '1',
      account: localStorage.getItem('account')
    };
  },
  methods: {
    // handleSelect(key, keyPath) {
    //   console.log(key);
    //   console.log(keyPath);
    // },
    errorHandler() {
      return true
    },
    PersonalCenterClick() {
      this.$router.push('personalcenter')
    },
    SignIn() {
      this.$router.push('login')
    },
    Mevip() {
      this.$router.push('membercenter')
    },
    Logout() {
      localStorage.removeItem('account')
      this.$router.push('login')
    }
  }
}
</script>

<style lang="less" scoped>
.logo {
  /* 内容剧中 */
  display: flex;
  justify-content: center;
}

.elmenu {
  /* 内容垂直居中 */
  display: flex;
  align-items: center;
}

.elmenuitem {
  font-size: large;
}

.el-menu-demo {
  // 添加背景图片
  background-image: url("@/assets/menubackgrouund.png");
  // 背景图片大小
  background-size: 100% 100%;
  background-color: rgb(0, 0, 0);
  // 固定顶部
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 999;
}

.el-menu.el-menu--horizontal {
  border-bottom: none;
}

/deep/.el-menu-item {
  background-color: rgba(255, 255, 255, 0);
}

/deep/.el-menu-item.is-active {
  background-color: #ffffff00 !important;
}

/deep/.el-menu-item:hover {
  background-color: #4000ff96 !important;
}

.badge_avatar {
  /* 内容垂直居中 */
  display: flex;
  align-items: center;
  /* 靠右展示 */
  float: right;
  /* 距离右边100px */
  margin-right: 50px;
}

.avatar {
  cursor: pointer;
}

.login {
  cursor: pointer;
  // 内容居中
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10vh;
  height: 3.5vh;
  // 边框
  border: 0.3vh solid rgb(132, 132, 248);
  border-radius: 20px;
  cursor: pointer;

  &:hover {
    border: 0.3vh solid rgb(81, 50, 255);
  }

  color: #ffffff;
}

.me-vip {
  cursor: pointer;
  // 内容居中
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10vh;
  height: 3.5vh;
  margin-right: 20px;
  // 边框
  border: 0.3vh solid rgb(132, 132, 248);
  border-radius: 20px;
  cursor: pointer;

  &:hover {
    border: 0.3vh solid rgb(81, 50, 255);
  }

  color: #ffffff;
}
</style>