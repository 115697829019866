<template>
  <div>
    <div class="login-div">
      <el-row v-if="isMobile == false">
        <el-col :span="12">
          <div class="login-div-left">
            <div style="z-index: 999; color: aliceblue;">
              <img src="@/assets/logo.png">
              <p class="biaoti">欢迎使用-叁个AI</p>
            </div>
            <p style="z-index: 1000; color:aliceblue; position: absolute; left: 20px; bottom: 20px;">Copyright © 2024
              海南鸿森智能科技有限公司 版权所有｜
              <a href="https://beian.miit.gov.cn/" target="_blank">琼ICP备2023011196号</a>
            </p>

          </div>
        </el-col>
        <el-col :span="12">
          <div v-if="logIn" class="login-div-right">
            <div class="login-method-outside">
              <div class="login-method message-method" @click="SwitchLoginMethod('message')">
                短信登陆
              </div>
              <div class="login-method emil-method" @click="SwitchLoginMethod('emil')">
                邮箱登陆
              </div>
              <div class="login-method account-method" @click="SwitchLoginMethod('account')">
                账号登录
              </div>
            </div>
            <div class="message-input-info" v-if="message">
              <el-input v-model="phone_number" placeholder="请输入手机号">
                <template slot="prepend">+86</template>
              </el-input>
              <div class="y-code">
                <el-input v-model="verification_code" placeholder="请输入验证码" style="width: 80%;"></el-input>
                <el-button style="width: 20%; height: 55px;" @click="GetVerificationCode('phone')">
                  <span v-if="countdown">{{ formattedTime }}s</span>
                  <span v-else>获取验证码</span>
                </el-button>
              </div>

              <div class="protocol">
                <i class="el-icon-circle-check" @click="Agree" id="protocol"></i>
                <span style="margin-top: 20px; font-size: 16px;">
                  我已经阅读并接受
                  <a href="https://sagaai.cn/protocol/protocol.html" target="_blank">《隐私政策》</a>
                </span>

              </div>
              <el-button class="ok" type="primary" @click="Login('phone')">
                确 定
              </el-button>
              <div class="register-button" @click="RegisterButton">注 册</div>
            </div>
            <div class="message-input-info" v-if="emilmethod">
              <el-input v-model="emil_address" placeholder="请输入邮箱">
                <template slot="append">.com</template>
              </el-input>
              <div class="y-code">
                <el-input v-model="verification_code_emil" placeholder="请输入验证码" style="width: 80%;"></el-input>
                <el-button style="width: 20%; height: 55px;" @click="GetVerificationCode('emil')">
                  <span v-if="countdown">{{ formattedTime }}s</span>
                  <span v-else>获取验证码</span>
                </el-button>
              </div>

              <div class="protocol">
                <i class="el-icon-circle-check" @click="Agree" id="protocol"></i>
                <span style="margin-top: 20px; font-size: 16px;">
                  我已经阅读并接受
                  <a href="https://sagaai.cn/protocol/protocol.html" target="_blank">《隐私政策》</a>
                </span>

              </div>
              <el-button class="ok" type="primary" @click="Login('emil')">
                确 定
              </el-button>
              <div class="register-button" @click="RegisterButton">注 册</div>
            </div>
            <div class="message-input-info" v-if="emailaccount">
              <el-input v-model="account_emil_address" placeholder="请输入账号">
                <template slot="append">.com</template>
              </el-input>
              <el-input type="password" v-model="account_password" placeholder="请输入密码"></el-input>
              <div class="protocol">
                <i class="el-icon-circle-check" @click="Agree" id="protocol"></i>
                <span style="margin-top: 20px; font-size: 16px;">
                  我已经阅读并接受
                  <a href="https://sagaai.cn/protocol/protocol.html" target="_blank">《隐私政策》</a>
                </span>

              </div>
              <el-button class="ok" type="primary" @click="Login('account_emil')">
                确 定
              </el-button>
              <div class="register-button" @click="RegisterButton">注 册</div>
            </div>

          </div>
          <div v-if="Register" class="login-div-right">
            <div class="login-method-outside">
              <div class="register-method message-method login-method" @click="RegisterMethod('phone')">
                手机号注册
              </div>
              <div class="register-method login-method" @click="RegisterMethod('emil')">
                邮箱注册
              </div>
            </div>
            <div class="message-input-info" v-if="phoneRegister">
              <el-form :model="MobileRegistrationInfo" class="dynamic">
                <el-form-item prop="phone_number" :rules="[{ required: true, message: '请输入手机号', trigger: 'blur' },
                { validator: checkPhone, trigger: ['change'] },]">
                  <el-input v-model="MobileRegistrationInfo.phone_number" placeholder="请输入手机号">
                    <template slot="prepend">+86</template></el-input>
                </el-form-item>
                <el-form-item prop="verification_code" :rules="[{
                  required: true, message: '请输入验证码',
                  trigger: 'blur'
                }]">
                  <div class="y-code">
                    <el-input v-model="MobileRegistrationInfo.verification_code" style="width: 80%;"
                      placeholder="请输入验证码">

                    </el-input>
                    <el-button style="width: 20%; height: 55px;" @click="GetVerificationCode('register_phone')">
                      <span v-if="countdown">{{ formattedTime }}s</span>
                      <span v-else>获取验证码</span>
                    </el-button>
                  </div>

                </el-form-item>
                <div class="protocol">
                  <i class="el-icon-circle-check" @click="Agree" id="protocol"></i>
                  <span style="margin-top: 20px; font-size: 16px;">
                    我已经阅读并接受

                    <a href="https://sagaai.cn/protocol/protocol.html" target="_blank">《隐私政策》</a>
                  </span>

                </div>
                <el-form-item>

                  <el-button class="ok" type="primary" @click="RegisterClick('phone')">注册</el-button>

                </el-form-item>
                <div class="register-button" @click="LogINButton">已有账号去登录</div>
              </el-form>
            </div>
            <div class="message-input-info" v-if="emilRegister">
              <el-form :model="EmilRegistrationInfo" class="dynamic">
                <el-form-item prop="emil_address" :rules="[{ required: true, message: '请输入邮箱地址', trigger: 'blur' },
                { type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }]">
                  <el-input v-model="EmilRegistrationInfo.emil_address" placeholder="请输入邮箱地址">
                    <template slot="append">.com</template></el-input>
                </el-form-item>
                <el-form-item prop="verification_code_emil" :rules="[{
                  required: true, message: '请输入验证码',
                  trigger: 'blur'
                }]">
                  <div class="y-code">
                    <el-input v-model="EmilRegistrationInfo.verification_code_emil" style="width: 80%;"
                      placeholder="请输入验证码">

                    </el-input>
                    <el-button style="width: 20%; height: 55px;" @click="GetVerificationCode('register_emil')">
                      <span v-if="countdown">{{ formattedTime }}s</span>
                      <span v-else>获取验证码</span>
                    </el-button>
                  </div>

                </el-form-item>
                <div class="protocol">
                  <i class="el-icon-circle-check" @click="Agree" id="protocol"></i>
                  <span style="margin-top: 20px; font-size: 16px;">
                    我已经阅读并接受
                    <a href="https://sagaai.cn/protocol/protocol.html" target="_blank">《隐私政策》</a>
                  </span>

                </div>
                <el-form-item>
                  <el-button class="ok" type="primary" @click="RegisterClick('emil')">注册</el-button>

                </el-form-item>
                <div class="register-button" @click="LogINButton">已有账号去登录</div>
              </el-form>
            </div>
          </div>
        </el-col>
      </el-row>
      <div v-else>
        <div class="login-div-left">
          <div
            style="z-index: 999;color: aliceblue;display: flex;align-content: stretch;flex-direction: column;flex-wrap: wrap;align-items: center;">
            <img src="@/assets/logo.png">
            <p class="biaoti">欢迎使用-叁个AI</p>
            <div v-if="logIn" class="login-div-right">
              <div class="login-method-outside">
                <div class="login-method message-method" @click="SwitchLoginMethod('message')">
                  短信登陆
                </div>
                <div class="login-method emil-method" @click="SwitchLoginMethod('emil')">
                  邮箱登陆
                </div>
                <div class="login-method account-method" @click="SwitchLoginMethod('account')">
                  账号登录
                </div>
              </div>
              <div class="message-input-info" v-if="message">
                <el-input v-model="phone_number" placeholder="请输入手机号">
                  <template slot="prepend">+86</template>
                </el-input>
                <div class="y-code">
                  <el-input v-model="verification_code" placeholder="请输入验证码" style="width: 80%;"></el-input>
                  <el-button style="width: 20%; height: 3.5em;" @click="GetVerificationCode('phone')">
                    <span v-if="countdown">{{ formattedTime }}s</span>
                    <span v-else>获取验证码</span>
                  </el-button>
                </div>

                <div class="protocol">
                  <i class="el-icon-circle-check" @click="Agree" id="protocol"></i>
                  <span style="margin-top: 1em; font-size: 0.8em;">
                    我已经阅读并接受
                    <a href="https://sagaai.cn/protocol/protocol.html" target="_blank">《隐私政策》</a>
                  </span>

                </div>
                <el-button class="ok" type="primary" @click="Login('phone')">
                  确 定
                </el-button>
                <div class="register-button" @click="RegisterButton">注 册</div>
              </div>
              <div class="message-input-info" v-if="emilmethod">
                <el-input v-model="emil_address" placeholder="请输入邮箱">
                  <template slot="append">.com</template>
                </el-input>
                <div class="y-code">
                  <el-input v-model="verification_code_emil" placeholder="请输入验证码" style="width: 80%;"></el-input>
                  <el-button style="width: 20%; height: 3.5em;" @click="GetVerificationCode('emil')">
                    <span v-if="countdown">{{ formattedTime }}s</span>
                    <span v-else>获取验证码</span>
                  </el-button>
                </div>

                <div class="protocol">
                  <i class="el-icon-circle-check" @click="Agree" id="protocol"></i>
                  <span style="margin-top: 1em; font-size: 0.8em;">
                    我已经阅读并接受
                    <a href="https://sagaai.cn/protocol/protocol.html" target="_blank">《隐私政策》</a>
                  </span>

                </div>
                <el-button class="ok" type="primary" @click="Login('emil')">
                  确 定
                </el-button>
                <div class="register-button" @click="RegisterButton">注 册</div>
              </div>
              <div class="message-input-info" v-if="emailaccount">
                <el-input v-model="account_emil_address" placeholder="请输入账号">
                  <template slot="append">.com</template>
                </el-input>
                <el-input type="password" v-model="account_password" placeholder="请输入密码"></el-input>
                <div class="protocol">
                  <i class="el-icon-circle-check" @click="Agree" id="protocol"></i>
                  <span style="margin-top: 1em; font-size: 0.8em;">
                    我已经阅读并接受
                    <a href="https://sagaai.cn/protocol/protocol.html" target="_blank">《隐私政策》</a>
                  </span>

                </div>
                <el-button class="ok" type="primary" @click="Login('account_emil')">
                  确 定
                </el-button>
                <div class="register-button" @click="RegisterButton">注 册</div>
              </div>

            </div>
            <div v-if="Register" class="login-div-right">
              <div class="login-method-outside">
                <div class="register-method message-method login-method" @click="RegisterMethod('phone')">
                  手机号注册
                </div>
                <div class="register-method login-method" @click="RegisterMethod('emil')">
                  邮箱注册
                </div>
              </div>
              <div class="message-input-info" v-if="phoneRegister">
                <el-form :model="MobileRegistrationInfo" class="dynamic">
                  <el-form-item prop="phone_number" :rules="[{ required: true, message: '请输入手机号', trigger: 'blur' },
                  { validator: checkPhone, trigger: ['change'] },]">
                    <el-input v-model="MobileRegistrationInfo.phone_number" placeholder="请输入手机号">
                      <template slot="prepend">+86</template></el-input>
                  </el-form-item>
                  <el-form-item prop="verification_code" :rules="[{
                    required: true, message: '请输入验证码',
                    trigger: 'blur'
                  }]">
                    <div class="y-code">
                      <el-input v-model="MobileRegistrationInfo.verification_code" style="width: 80%;"
                        placeholder="请输入验证码">

                      </el-input>
                      <el-button style="width: 20%; height: 3.5em;" @click="GetVerificationCode('register_phone')">
                        <span v-if="countdown">{{ formattedTime }}s</span>
                        <span v-else>获取验证码</span>
                      </el-button>
                    </div>

                  </el-form-item>
                  <div class="protocol">
                    <i class="el-icon-circle-check" @click="Agree" id="protocol" style="margin-top: 0"></i>
                    <span style="font-size: 0.8em; margin-top: 1em;">
                      我已经阅读并接受

                      <a href="https://sagaai.cn/protocol/protocol.html" target="_blank">《隐私政策》</a>
                    </span>

                  </div>
                  <el-form-item>

                    <el-button class="ok" type="primary" @click="RegisterClick('phone')">注册</el-button>

                  </el-form-item>
                  <div class="register-button" @click="LogINButton">已有账号去登录</div>
                </el-form>
              </div>
              <div class="message-input-info" v-if="emilRegister">
                <el-form :model="EmilRegistrationInfo" class="dynamic">
                  <el-form-item prop="emil_address" :rules="[{ required: true, message: '请输入邮箱地址', trigger: 'blur' },
                  { type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }]">
                    <el-input v-model="EmilRegistrationInfo.emil_address" placeholder="请输入邮箱地址">
                      <template slot="append">.com</template></el-input>
                  </el-form-item>
                  <el-form-item prop="verification_code_emil" :rules="[{
                    required: true, message: '请输入验证码',
                    trigger: 'blur'
                  }]">
                    <div class="y-code">
                      <el-input v-model="EmilRegistrationInfo.verification_code_emil" style="width: 80%;"
                        placeholder="请输入验证码">

                      </el-input>
                      <el-button style="width: 20%; height: 3.5em;" @click="GetVerificationCode('register_emil')">
                        <span v-if="countdown">{{ formattedTime }}s</span>
                        <span v-else>获取验证码</span>
                      </el-button>
                    </div>

                  </el-form-item>
                  <div class="protocol">
                    <i class="el-icon-circle-check" @click="Agree" id="protocol" style="margin-top: 0"></i>
                    <span style="font-size: 0.8em; margin-top: 1em;">
                      我已经阅读并接受
                      <a href="https://sagaai.cn/protocol/protocol.html" target="_blank">《隐私政策》</a>
                    </span>

                  </div>
                  <el-form-item>
                    <el-button class="ok" type="primary" @click="RegisterClick('emil')">注册</el-button>

                  </el-form-item>
                  <div class="register-button" @click="LogINButton">已有账号去登录</div>
                </el-form>
              </div>
            </div>
          </div>
          <p style="z-index: 1000; color:aliceblue; position: absolute; left: 20px; bottom: 20px;">Copyright © 2024
            海南鸿森智能科技有限公司 版权所有｜
            <a href="https://beian.miit.gov.cn/" target="_blank">琼ICP备2023011196号</a>
          </p>

        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { request } from '@/utils/request.js'
export default {
  data() {
    return {
      isMobile: false,
      logIn: true,
      Register: false,
      message: true,
      emilmethod: false,
      emailaccount: false,
      emil: false,
      account: false,
      phoneRegister: true,
      emilRegister: false,
      totalTime: 59, // 倒计时总时长为60秒
      phone_number: '',  // 手机号
      emil_address: '',  // 邮箱地址
      account_emil_address: '',  // 邮箱账号
      account_password: '',  // 账号密码
      verification_code: '',  // 验证码
      verification_code_emil: '',  // 邮箱验证码
      countdown: false,
      agree: false,
      MobileRegistrationInfo: {
        phone_number: '',
        verification_code: '',
      },
      EmilRegistrationInfo: {
        emil_address: '',  // 邮箱地址
        verification_code_emil: '',  // 邮箱验证码

      }

    }
  },
  methods: {
    SwitchLoginMethod(method) {
      switch (method) {
        case 'message':
          document.getElementsByClassName('login-method')[0].style.color = '#ffffff'
          document.getElementsByClassName('login-method')[1].style.color = '#a6a6a6'
          document.getElementsByClassName('login-method')[2].style.color = '#a6a6a6'
          if (this.message == false) {
            this.clearCountdown();
            this.countdown = false;
            this.totalTime = 59;
          }
          this.message = true;
          this.emilmethod = false;
          this.emailaccount = false;
          break;
        case 'emil':
          document.getElementsByClassName('login-method')[1].style.color = '#ffffff'
          document.getElementsByClassName('login-method')[0].style.color = '#a6a6a6'
          document.getElementsByClassName('login-method')[2].style.color = '#a6a6a6'
          if (this.emilmethod == false) {
            this.clearCountdown();
            this.countdown = false;
            this.totalTime = 59;
          }
          this.message = false;
          this.emilmethod = true;
          this.emailaccount = false;
          break;
        case 'account':
          document.getElementsByClassName('login-method')[2].style.color = '#ffffff'
          document.getElementsByClassName('login-method')[1].style.color = '#a6a6a6'
          document.getElementsByClassName('login-method')[0].style.color = '#a6a6a6'
          if (this.emailaccount == false) {
            this.clearCountdown();
            this.countdown = false;
            this.totalTime = 59;
          }
          this.message = false;
          this.emilmethod = false;
          this.emailaccount = true;
          break;
        default:
          break;
      }
    },
    startCountdown() {
      this.interval = setInterval(() => {
        if (this.totalTime > 0) {
          this.totalTime--;
        } else {
          this.clearCountdown();
          this.countdown = false;
          this.totalTime = 59;
        }
      }, 1000);
    },
    clearCountdown() {
      clearInterval(this.interval);
    },
    GetVerificationCode(method) {
      if (method == 'phone') {
        if (this.phone_number != '') {
          if (this.phone_number.length == 11 && (/^1[3456789]\d{9}$/.test(this.phone_number))) {
            if (this.countdown == false) {
              this.startCountdown();
              request(this.global.FOOOCUS_API_BASE_URL + '/api/get_sms_code', 'post', {
                mobile: this.phone_number
              })
            }
            this.countdown = true;
          } else {
            this.$message({
              message: '请输入正确的手机号',
              type: 'warning'
            });
          }
        } else {
          this.$message({
            message: '请输入手机号',
            type: 'warning'
          });
        }
      } else if (method == 'emil') {

        if (this.emil_address != '') {
          if (this.emil_address.indexOf('@') != -1) {
            if (this.countdown == false) {
              this.startCountdown();
              request(this.global.FOOOCUS_API_BASE_URL + '/api/get_verification_code', 'post', {
                e_mail: this.emil_address
              })
            }
            this.countdown = true;
          } else {
            this.$message({
              message: '请输入正确的邮箱地址',
              type: 'warning'
            });
          }
        } else {
          this.$message({
            message: '请输入邮箱地址',
            type: 'warning'
          });
        }
      } else if (method == 'register_phone') {
        if (this.MobileRegistrationInfo.phone_number != '') {
          if (this.MobileRegistrationInfo.phone_number.length == 11 && (/^1[3456789]\d{9}$/.test(this.MobileRegistrationInfo.phone_number))) {
            if (this.countdown == false) {
              this.startCountdown();
              request(this.global.FOOOCUS_API_BASE_URL + '/api/get_sms_code', 'post', {
                mobile: this.MobileRegistrationInfo.phone_number
              })
            }
            this.countdown = true;
          } else {
            this.$message({
              message: '请输入正确的手机号',
              type: 'warning'
            });
          }
        } else {
          this.$message({
            message: '请输入手机号',
            type: 'warning'
          });
        }

      } else if (method == 'register_emil') {
        if (this.EmilRegistrationInfo.emil_address != '') {
          if (this.EmilRegistrationInfo.emil_address.indexOf('@') != -1) {
            if (this.countdown == false) {
              this.startCountdown();
              request(this.global.FOOOCUS_API_BASE_URL + '/api/get_verification_code', 'post', {
                e_mail: this.EmilRegistrationInfo.emil_address
              })
            }
            this.countdown = true;
          } else {
            this.$message({
              message: '请输入正确的邮箱地址',
              type: 'warning'
            });
          }
        } else {
          this.$message({
            message: '请输入邮箱地址',
            type: 'warning'
          });
        }
      }

    },
    Agree() {
      const classname = document.getElementById('protocol').className;
      if (classname == 'el-icon-circle-check') {
        document.getElementById('protocol').className = 'el-icon-success'
        this.agree = true;
      }
      else {
        document.getElementById('protocol').className = 'el-icon-circle-check'
        this.agree = false;
      }
    },
    Login(method) {
      if (method == 'phone') {
        if (this.phone_number != '') {
          if (this.phone_number.length == 11 && (/^1[3456789]\d{9}$/.test(this.phone_number))) {
            if (this.verification_code != '') {
              if (this.agree == true) {
                request(this.global.FOOOCUS_API_BASE_URL + '/api/mobile/code/Sign_in', 'post', {
                  mobile: this.phone_number,
                  verification_code: this.verification_code,
                }).then(res => {
                  if (res.data.code == 200) {
                    localStorage.setItem('account', this.phone_number)
                    this.$router.push('home')
                  } else {
                    this.$message({
                      message: res.data.msg,
                      type: 'warning'
                    });
                  }
                })
              } else {
                this.$message({
                  message: '请先同意用户协议',
                  type: 'warning'
                });
              }
            } else {
              this.$message({
                message: '请输入验证码',
                type: 'warning'
              });
            }
          } else {
            this.$message({
              message: '请输入正确的手机号',
              type: 'warning'
            });
          }
        } else {
          this.$message({
            message: '请输入手机号',
            type: 'warning'
          });
        }
      } else if (method == 'emil') {
        if (this.emil_address != '') {
          if (this.emil_address.indexOf('@') != -1) {
            if (this.verification_code_emil != '') {
              if (this.agree == true) {
                request(this.global.FOOOCUS_API_BASE_URL + '/api/mailbox/code/Sign_in', 'post', {
                  e_mail: this.emil_address,
                  verification_code: this.verification_code_emil,
                }).then(res => {
                  if (res.data.code == 200) {
                    localStorage.setItem('account', this.emil_address)
                    this.$router.push('home')
                  } else {
                    this.$message({
                      message: res.data.msg,
                      type: 'warning'
                    });
                  }
                })
              } else {
                this.$message({
                  message: '请先同意用户协议',
                  type: 'warning'
                });
              }
            } else {
              this.$message({
                message: '请输入验证码',
                type: 'warning'
              });
            }
          } else {
            this.$message({
              message: '请输入正确的邮箱地址',
              type: 'warning'
            });
          }
        } else {
          this.$message({
            message: '请输入邮箱地址',
            type: 'warning'
          });
        }
      }
      else if (method == 'account_emil') {
        if (this.account_emil_address != '') {
          if (this.account_emil_address.indexOf('@') != -1) {
            if (this.account_password != '') {
              if (this.agree == true) {
                request(this.global.FOOOCUS_API_BASE_URL + '/api/mailbox/password/Sign_in', 'post', {
                  e_mail: this.account_emil_address,
                  e_mail_password: this.account_password,
                }).then(res => {
                  if (res.data.code == 200) {
                    localStorage.setItem('account', this.account_emil_address)
                    this.$router.push('home')
                  } else {
                    this.$message({
                      message: res.data.msg,
                      type: 'warning'
                    });
                  }
                })
              } else {
                this.$message({
                  message: '请先同意用户协议',
                  type: 'warning'
                });
              }
            } else {
              this.$message({
                message: '请输入密码',
                type: 'warning'
              });
            }
          } else {
            this.$message({
              message: '请输入正确的邮箱地址',
              type: 'warning'
            });
          }
        } else {
          this.$message({
            message: '请输入邮箱地址',
            type: 'warning'
          });
        }
      }


    },
    RegisterButton() {
      this.logIn = false;
      this.Register = true;
      this.clearCountdown();
      this.countdown = false;
      this.totalTime = 59;
    },
    LogINButton() {
      this.logIn = true;
      this.Register = false;
      this.clearCountdown();
      this.countdown = false;
      this.totalTime = 59;
    },
    RegisterMethod(method) {
      switch (method) {
        case 'phone':
          document.getElementsByClassName('register-method')[0].style.color = '#ffffff'
          document.getElementsByClassName('register-method')[1].style.color = '#a6a6a6'
          if (this.phoneRegister == false) {
            this.clearCountdown();
            this.countdown = false;
            this.totalTime = 59;
          }
          this.phoneRegister = true;
          this.emilRegister = false;
          break;
        case 'emil':
          document.getElementsByClassName('register-method')[1].style.color = '#ffffff'
          document.getElementsByClassName('register-method')[0].style.color = '#a6a6a6'
          if (this.emilRegister == false) {
            this.clearCountdown();
            this.countdown = false;
            this.totalTime = 59;
          }
          this.phoneRegister = false;
          this.emilRegister = true;
          break;
        default:
          break;
      }
    },
    checkPhone(rule, value, callback) {
      if (!/^1[34578]\d{9}$/.test(value)) {
        callback(new Error("请输入正确的手机号码!"));
      }
    },
    RegisterClick(method) {
      this.clearCountdown();
      this.countdown = false;
      this.totalTime = 59;
      if (method == 'phone') {
        if (this.MobileRegistrationInfo.phone_number != '') {
          if (this.MobileRegistrationInfo.phone_number.length == 11 && (/^1[3456789]\d{9}$/.test(this.MobileRegistrationInfo.phone_number))) {
            if (this.MobileRegistrationInfo.verification_code != '') {
              if (this.agree == true) {
                request(this.global.FOOOCUS_API_BASE_URL + '/api/mobile/Sign_up', 'post', {
                  mobile: this.MobileRegistrationInfo.phone_number,
                  verification_code: this.MobileRegistrationInfo.verification_code,
                }).then(res => {
                  if (res.data.code == 200) {
                    this.$message({
                      message: res.data.msg,
                      type: 'success'
                    });
                  } else {
                    this.$message({
                      message: res.data.msg,
                      type: 'warning'
                    });
                  }
                })
              } else {
                this.$message({
                  message: '请先同意用户协议',
                  type: 'warning'
                });
              }
            } else {
              this.$message({
                message: '请输入验证码',
                type: 'warning'
              });
            }
          } else {
            this.$message({
              message: '请输入正确的手机号',
              type: 'warning'
            });
          }
        } else {
          this.$message({
            message: '请输入手机号',
            type: 'warning'
          });
        }
      }
      else if (method == 'emil') {
        if (this.EmilRegistrationInfo.emil_address != '') {
          if (this.EmilRegistrationInfo.emil_address.indexOf('@') != -1) {
            if (this.EmilRegistrationInfo.verification_code_emil != '') {
              if (this.agree == true) {
                request(this.global.FOOOCUS_API_BASE_URL + '/api/mailbox/Sign_up', 'post', {
                  e_mail: this.EmilRegistrationInfo.emil_address,
                  verification_code: this.EmilRegistrationInfo.verification_code_emil,
                }).then(res => {
                  if (res.data.code == 200) {
                    localStorage.setItem('account', this.EmilRegistrationInfo.emil_address)
                    this.$router.push('home')
                  } else {
                    this.$message({
                      message: res.data.msg,
                      type: 'warning'
                    });
                  }
                })
              } else {
                this.$message({
                  message: '请先同意用户协议',
                  type: 'warning'
                });
              }
            } else {
              this.$message({
                message: '请输入验证码',
                type: 'warning'
              });
            }
          } else {
            this.$message({
              message: '请输入正确的邮箱地址',
              type: 'warning'
            });
          }
        } else {
          this.$message({
            message: '请输入邮箱地址',
            type: 'warning'
          });
        }
      }

    },
    checkMobile() {
      this.isMobile = window.innerWidth <= 768;
    }
  },
  mounted() {
    document.getElementsByClassName('login-method')[0].style.color = '#ffffff'
    if (localStorage.getItem('account')) {
      this.$router.push('home')
    }
    this.checkMobile();
    window.addEventListener('resize', this.checkMobile);
  },
  computed: {
    formattedTime() {
      // const minutes = Math.floor(this.totalTime / 60);
      const seconds = this.totalTime % 60;
      return `${seconds < 10 ? '0' + seconds : seconds}`;
    }
  },
  created() {

  },
  beforeDestroy() {
    // 组件销毁前清除计时器
    this.clearCountdown();
    window.removeEventListener('resize', this.checkMobile);

  },
}
</script>

<style lang="less" scoped>
.login-div {
  width: 100%;
}

.y-code {
  display: flex;
  align-items: flex-end;
}

.login-div-left {
  height: 100vh;
  width: 100%;
  position: relative;
  background-image: url("@/assets/Js2X6q0HQZ.jpg");
  background-size: cover;
  // 内容居中
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-div-left::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  // z-index: 1;
  background-color: rgba(0, 0, 0, 0.454);
  /* 确保伪元素在背景下 */
}

.biaoti {
  font-size: 30px;
  display: flex;
  justify-content: center;
}

.login-div-right {
  height: 100vh;
  width: 100%;
  position: relative;
  background-color: rgb(0, 0, 0);
  // 内容居中
  display: flex;
  // align-items: center;
  justify-content: center;
  color: #ffffff;
}

.login-method-outside {
  position: absolute;
  top: 30%;
  width: 100%;
  display: flex;
  justify-content: space-evenly;

  .login-method {
    display: inline-block;
    color: #a6a6a6;
    font-size: 2.5vh;
    cursor: pointer;
    // 字体加粗
    font-weight: bold;
  }
}

.message-input-info {
  // height: 50%;
  width: 75%;
  position: absolute;
  top: 35%;

  // background-color: #6d6d6d3f;
  .el-button {
    background-color: #000000;
    color: #ffffff;
    margin: 0;
    padding: 0;
    font-size: 0.8em;
    line-height: 0;

    // 鼠标移入
    &:hover {
      color: #6f79ff;
    }

  }

  .el-input {
    // 取消边框
    // border: 2px solid #6f79ff;
    border-radius: 10px;
    margin-top: 20px;

    /deep/.el-input__inner {
      height: 55px;
      background-color: #000000;

      font-size: 16px;
      color: #ffffff;

    }

    /deep/.el-input__inner::placeholder {
      color: #888888;
    }
  }

  .ok {
    width: 100%;
    height: 55px;
    background-color: #6f79ff;
    border-radius: 50px;
    margin-top: 20px;
    font-size: 20px;

    &:hover {
      color: #ffffff;
    }

    &:active {
      background-color: #3846fd;
      color: #383838;
    }
  }

  .protocol {
    display: flex;
    // justify-content: center;
    align-items: center;

    .el-icon-circle-check,
    .el-icon-success {
      color: #ffffff;
      font-size: 18px;
      margin-top: 22px;
      cursor: pointer;
    }
  }

}


/deep/.el-input-group__prepend,
/deep/.el-input-group__append {
  background-color: #6d6d6d3f;
  color: #aaaaaa;
  // border: 2px solid #6f79ff;
}

.register-button {
  color: #6f79ff;
  font-size: 20px;
  margin-top: 20px;
  cursor: pointer;
  // 漂浮在右边
  position: absolute;
  right: 0;
}

.countdown {
  width: 20%;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #6d6d6d3f;
  border-radius: 5px;
  border: 1px solid #ffffff;
  font: 1.3em sans-serif;
}

/deep/.el-form-item__label {
  display: flex;
  align-items: center;
  height: 55px;
  margin-top: 20px;
  color: #ffffff;
  font-size: 20px;
}
/deep/.el-form-item{
  margin-bottom: 0;
}
/deep/ .el-form-item__content {
  margin-left: 0;
}
</style>

<style lang="less" scoped>
@media screen and (max-width: 768px) {
  ::v-deep .login-method {
    font-size: 1.5em !important;
  }

  ::v-deep .message-input-info {
    width: 90% !important;
    top: 12% !important;
  }

  ::v-deep .el-input__inner {
    height: 3.5em !important;
    font-size: 0.8em !important;
  }

  ::v-deep .el-input-group__prepend {
    font-size: 0.8em !important;
    width: 10px;
  }

  ::v-deep .ok {
    width: 100% !important;
    height: 2em !important;
  }

  ::v-deep .register-button {
    color: #6f79ff;
    font-size: 1em !important;
    margin-top: 1em;
    cursor: pointer;
    position: absolute;
    right: 0;
  }

  ::v-deep .el-icon-circle-check,
  .el-icon-success {
    color: #ffffff;
    font-size: 18px;
    margin-top: 0.6em !important;
    cursor: pointer;
  }

  ::v-deep .el-form-item {
    margin-bottom: 0 !important;
  }

  ::v-deep .el-form-item__content {
    line-height: 0;
  }

  ::v-deep .login-div-right {
    height: 60vh;
    background-color: #00000000;
    width: 95vw;
  }

  ::v-deep .login-method-outside {
    top: 5%;
  }
}
</style>